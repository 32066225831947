.course-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
  }
  
  .description-item {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 10px;
    /* padding-right: 0; */
    margin: 10px 0;
    width: 300px;
   text-align: left; 
   transition: transform 0.3s ease, box-shadow 0.3s ease;  

} 
.description-item:hover {
  transform: scale(1.1); /* Pops the button slightly when hovering */

}
  .description-item span {
    color:  #ffa500;
    margin-right: 10px;
  }
  .description-item p b{
    color: #47f300;
  text-shadow: #47f300 0 0 10px, /* First shadow */
               #47f300 0 0 20px;;
    
  }
  @media screen and (max-width: 768px){
.course-description {
 align-items: center;
 padding-right: 15%;
}
  }
  