body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    /* text-decoration: none; */
  }
  
  .course-header {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    padding: 1px;
    background-color: black;
    align-items: center;
  }
  
  .course-card img {
    height: auto;
    width: 220px;
  }
  
  .course-logo img {
    height: 100px;
    margin: 0;
    padding: 0; /* Adjust the height of the logo */
  }
  
  .course-buy_now {
    font-size: large;
    font-weight: bolder;
    text-decoration: double;
    padding: 10px;
    color: white;
    border: 1px solid rgb(0, 137, 50);
    border-radius: 5px;
    background-color: #08bc50;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  #course-buy {
    margin: 5px;
    background-color: white;
    margin-top: 10px;
    font-size: large;
    font-weight: bolder;
    padding: 20px;
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .course-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
  }
  
  .course-offer {
    color: red;
    font-weight: bolder;
    margin: 0;
  }
  .course-image{
    width: 270px;
  }
  
  .course-card {
    border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 270px; /* Adjust card width for desktop */
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s; /* Animation for card hover effect */
  }
  
  .course-card:hover {
    transform: scale(1.05); /* Slightly enlarge card on hover */
  }
  
  .course-card h3 {
    font-size: 1.2em;
  }
  .course-buy_now,
.course-buy {
  width: 100%; /* Make buttons take the full width of the card */
  margin-top: 10px; /* Space between buttons */
}
  
  .course-price {
    font-weight: bold;
    font-size: 1.1em;
    margin: 0;
  }
  
  .course-original-price {
    text-decoration: line-through;
    color: #888;
    font-size: 27px;
  }
  
  .course-bestseller {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #ffd700; /* Gold color for bestseller */
  }
  
  .course-gst {
    color: darkgreen;
    margin: 0;
    font-size: 10px;
    padding-top: 0;
  }
  
  .course-footer {
    text-align: center;
    margin-top: 70px;
    padding: 20px;
    background-color: #757575;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .course-cards {
      flex-direction: column; /* Stack cards vertically */
      align-items: center; /* Center align cards */
    }
  
    .course-card {
      width: 270px; /* Full width for cards on mobile */
      margin: 10px 0; /* Vertical margin for spacing */
      display: flex;
      flex-direction: column; /* Stack cards vertically */
    }
  }
  