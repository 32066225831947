.cta-section {
    padding: 30px;
    background-color: #000;
    text-align: center;
  }
  
  .cta-button {
    background-color: red;
    color: white;
    padding: 15px 30px;
    font-weight: bolder;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 10px 5px 10px grey;
    animation: shake 0.5s ease-in-out  2.5s infinite;
    /* animation-delay: 2s; */
  }
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .cta-button:hover {
    /* background-color: #ff66b2; */
    transform: scale(1.1);
    box-shadow: 0px 7px 10px grey;
  }
  
  .cta-section p {
    /* color:greenyellow; */
    font-size: 25px;
    font-weight: bold;
    color: #47f300;
    text-shadow: 
        #47f300 0 0 10px, /* First shadow */
        #47f300 0 0 20px;
  }
  .dis{
    font-size: 25px;
    font-weight: bold;
    color: #47f300;
    text-shadow: 
        #47f300 0 0 10px, /* First shadow */
        #47f300 0 0 20px;
  }
  